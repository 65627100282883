function Footer() {
  return (
    <div 
      style={{ backgroundColor: "#000B23" }}
      className="fixed bottom-0 flex w-full items-center justify-center p-4 z-10"
    >
      <button 
        className="text-xs lg:text-sm text-white"
        onClick={() => window.location = "mailto:contact@qalmo.fr"}
      >
        contact@qalmo.fr
      </button>
    </div>
  );
}

export default Footer;
