function Support() {
  return (
    <div className="max-w-3xl mx-auto bg-white p-8 rounded-lg shadow-lg text-justify">
      <h1 className="text-4xl font-bold mb-6">Support de qalmo</h1>
      <p className="mb-4">
        Si vous avez besoin d'aide ou souhaitez nous contacter pour des questions relatives à l'application <strong className="font-bold">qalmo</strong>, vous pouvez nous joindre à l’adresse email ci-dessous :
      </p>
      <p className="mb-4">
        <strong>Email :</strong> <a href="mailto:support@qalmo.fr" className="text-blue-600 hover:underline">support@qalmo.fr</a>
      </p>

      <h2 className="text-2xl font-semibold mb-4">FAQ</h2>

      <h3 className="text-xl font-semibold mt-4 mb-2">Comment créer une tâche ?</h3>
      <p className="mb-4">Une fois connecté, cliquez sur le bouton principal (+) en bas de l'écran afin d'ouvrir le formulaire. Vous pourrez renseigner autant de champs que vous le voulez, seul le titre est obligatoire. Une fois cela terminé, cliquez sur "Créer" pour voir votre tâche apparaître dans votre vue principale.</p>

      <h3 className="text-xl font-semibold mt-4 mb-2">Comment ajouter ses amis ?</h3>
      <p className="mb-4">Cliquez sur l'icône Amis en haut à droite afin d'être redirigé vers la page "Mes amis". Vous pourrez rechercher des utilisateurs via leur pseudo et leur envoyer une demande d'ajout, qu'ils devront accepter.</p>

      <h3 className="text-xl font-semibold mt-4 mb-2">Comment publier une tâche et voir les interactions ?</h3>
      <p className="mb-4">Une tâche peut être partagée de plusieurs manières différentes : soit depuis le formulaire via le bouton "Privée" lors de sa création ou modification, soit en maintenant appuyé sur la tâche et en cliquant sur "Partager à mes amis".</p>
      <p className="mb-4">La tâche apparaît désormais dans votre feed ainsi que celui de vos amis. Vous pouvez voir les interactions de vos amis via l'onglet "Social" depuis votre tâche, ou directement depuis le feed.</p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">Problèmes techniques</h2>
      <p className="mb-4">
        Pour toute difficulté technique, vous pouvez envoyer un e-mail à notre équipe de support avec une description détaillée de votre problème, y compris, si possible, des captures d'écran ou des étapes pour reproduire l'erreur.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">Retour d'expérience</h2>
      <p className="mb-4">
        Vos retours sont précieux pour nous. Si vous avez des suggestions ou des idées pour améliorer l'application, n'hésitez pas à nous les envoyer par e-mail !
      </p>

      <div className="text-center mt-8 text-sm text-gray-500">
        <p>&copy; 2024 qalmo. Tous droits réservés.</p>
      </div>
    </div>
  );
}

export default Support;
