function PrivatePolicy() {
  return (
    <div className="max-w-3xl mx-auto bg-white p-8 rounded-lg shadow-lg text-justify">
      <h1 className="text-4xl font-bold mb-6">Politique de confidentialité de qalmo (Version Mobile)</h1>
      <p className="mb-4"><strong>Dernière mise à jour :</strong> 16/09/2024</p>

      <h2 className="text-2xl font-semibold mb-4">1. Entité responsable</h2>
      <p className="mb-4">Cette politique de confidentialité est associée à l’application <strong className="font-bold">qalmo</strong>, développée par Victor BACOT & Quentin SAGNOL. Pour toute question ou demande concernant la confidentialité, veuillez nous contacter à l'adresse ci-dessous :</p>
      <p className="mb-4"><strong>Email :</strong> <a href="mailto:policy@qalmo.fr" className="text-blue-600 hover:underline">policy@qalmo.fr</a></p>

      <h2 className="text-2xl font-semibold mb-4">2. Données collectées</h2>
      <p className="mb-4"><strong className="font-bold">qalmo</strong> peut accéder aux types de données suivants :</p>
      <p className="mb-4"><strong>Données personnelles :</strong> Informations comme votre nom, adresse e-mail, etc.</p>
      <p className="mb-4"><strong>Données techniques :</strong> Informations relatives à votre appareil, telles que modèle, système d’exploitation, etc.</p>
      <p className="mb-4"><strong>Données d’utilisation :</strong> Informations sur la manière dont vous interagissez avec l’application.</p>

      <h2 className="text-2xl font-semibold mb-4">3. Utilisation des données</h2>
      <p className="mb-4">Les données que nous recueillons sont utilisées pour vous fournir et améliorer les services offerts par <strong className="font-bold">qalmo</strong>, personnaliser votre expérience, répondre à vos demandes de support, et assurer la sécurité de l’application.</p>

      <h2 className="text-2xl font-semibold mb-4">4. Partage des données</h2>
      <p className="mb-4">Vos données personnelles ne sont partagées qu'avec des <strong>partenaires de service</strong> (serveurs, ...) et en cas de demande légale par des <strong>autorités compétentes</strong>. Nous ne vendons ni ne louons vos données.</p>

      <h2 className="text-2xl font-semibold mb-4">5. Sécurité des données</h2>
      <p className="mb-4">Nous mettons en œuvre des mesures de sécurité strictes pour protéger vos informations contre tout accès non autorisé, divulgation ou destruction.</p>

      <h2 className="text-2xl font-semibold mb-4">6. Conservation des données</h2>
      <p className="mb-4">Nous conservons vos informations aussi longtemps que nécessaire pour atteindre les objectifs décrits dans cette politique. Les données sont ensuite supprimées de manière sécurisée.</p>

      <h2 className="text-2xl font-semibold mb-4">7. Suppression des données</h2>
      <p className="mb-4">Vous pouvez demander la suppression de vos données via l’application ou en nous contactant directement.</p>

      <h2 className="text-2xl font-semibold mb-4">8. Accès et modification des données</h2>
      <p className="mb-4">Vous avez le droit de consulter, corriger ou supprimer vos informations personnelles en nous contactant à tout moment.</p>

      <h2 className="text-2xl font-semibold mb-4">9. Cookies et technologies similaires</h2>
      <p className="mb-4">Nous utilisons des cookies et technologies similaires pour améliorer l'expérience utilisateur et personnaliser nos services.</p>

      <h2 className="text-2xl font-semibold mb-4">10. Politique de confidentialité accessible</h2>
      <p className="mb-4">Cette politique est accessible via un lien direct dans l'application <strong className="font-bold">qalmo</strong> ainsi que sur la page des Stores respectifs. Elle est disponible via une URL active lisible dans tout navigateur standard.</p>

      <h2 className="text-2xl font-semibold mb-4">11. Conformité au RGPD</h2>
      <p className="mb-4">Si vous êtes situé dans l'Espace économique européen (EEE), le traitement de vos données personnelles est régi par le <strong>Règlement Général sur la Protection des Données (RGPD)</strong>.</p>
      <p className="mb-4">Dans le cadre du RGPD, vous disposez des droits suivants concernant vos données personnelles :</p>
      <ul className="list-disc list-inside mb-4">
        <li>Le droit d'accès à vos données personnelles.</li>
        <li>Le droit de rectification si les informations sont incorrectes ou incomplètes.</li>
        <li>Le droit de suppression ("droit à l'oubli").</li>
        <li>Le droit de limiter le traitement de vos données.</li>
        <li>Le droit de vous opposer au traitement de vos données.</li>
        <li>Le droit à la portabilité des données.</li>
      </ul>
      <p className="mb-4">Pour exercer l’un de ces droits, veuillez nous contacter à l'adresse e-mail fournie dans la section "Contact". Nous nous engageons à répondre à vos demandes dans un délai raisonnable et à respecter vos droits en matière de protection des données.</p>

      <h2 className="text-2xl font-semibold mb-4">12. Mises à jour de la politique</h2>
      <p className="mb-4">Nous nous réservons le droit de mettre à jour cette politique de confidentialité pour refléter les changements de nos pratiques ou des lois applicables. Toute modification sera notifiée dans l’application.</p>

      <h2 className="text-2xl font-semibold mb-4">13. Contact</h2>
      <p className="mb-4">Pour toute question concernant cette politique de confidentialité ou pour exercer vos droits en matière de protection des données, veuillez nous contacter :</p>
      <p className="mb-4"><strong>Email :</strong> <a href="mailto:policy@qalmo.fr" className="text-blue-600 hover:underline">policy@qalmo.fr</a></p>

      <div className="text-center mt-8 text-sm text-gray-500">
        <p>&copy; 2024 qalmo. Tous droits réservés.</p>
      </div>
    </div>
  );
};

export default PrivatePolicy;
