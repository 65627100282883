import { Link } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import appstore from './appstore.png';
import googleplay from './googleplay.webp';
import phone from './phone.svg';

function App() {
  return (
    <div 
      className="h-screen flex flex-col"
      style={{ fontFamily: 'Fraunces, serif' }}
    >
      <Header />
      <div className="flex flex-col lg:flex-row items-center space-y-8 lg:space-y-0 p-6 lg:px-16">
        <div className="flex-1 lg:flex-none lg:w-1/2 space-y-6 lg:space-y-24">
          <h1 className="text-left text-xl lg:text-5xl font-bold">
            La bêta de qalmo, l'application où chaque tâche devient un partage, est ouverte !
          </h1>
          <div className="flex flex-row justify-center space-x-5">
            <div>
              <Link to="/ios">
                <img className="w-40 lg:w-52" src={appstore} />
              </Link>
            </div>
            <div className="flex flex-col w-40 lg:w-52 space-y-2">
              <Link to="/android">
                <img className="w-40 lg:w-52" src={googleplay} />
              </Link>
              <p 
                style={{ color: "#798194" }}
                className="text-xs hidden lg:flex lg:flex-col"
              >
                Disponible pour les pré-inscrits, sinon envoie ton mail ici :<br />
                <div className="flex flex-row">
                  <a 
                    style={{ color: "#5879ED", textDecoration: "underline" }}
                    href="https://bit.ly/qalmo"
                  >
                    https://bit.ly/qalmo
                  </a>&nbsp;! 🤪
                </div>
              </p>
            </div>
          </div>
          <p 
            style={{ color: "#798194" }}
            className="text-xs lg:hidden"
          >
            Disponible sur Android pour les pré-inscrits uniquement, sinon, envoie ton mail ici :&nbsp;
            <a 
              style={{ color: "#5879ED", textDecoration: "underline" }}
              href="https://bit.ly/qalmo"
            >
              https://bit.ly/qalmo
            </a> ! 🤪
          </p>
        </div>
        <div className="flex w-full items-center justify-center pb-14 lg:pb-0">
          <img className="-rotate-12 w-40 lg:w-52" src={phone} alt="phone" />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
