import logo from './logo.svg';

function Header() {
  return (
    <div className="flex justify-center">
      <img src={logo} width={500} alt="logo" />
    </div>
  );
}

export default Header;
