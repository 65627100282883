import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './index.css';
import App from './App';
import PrivatePolicy from './PrivatePolicy';
import Support from './Support';
import reportWebVitals from './reportWebVitals';

const ExternalRedirect = ({ url }) => {
  window.location.href = url;
  return null;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/android",
    element: <ExternalRedirect url="https://play.google.com/store/apps/details?id=com.frdev.qalmo" />,
  },
  {
    path: "/confidentialite",
    element: <PrivatePolicy />,
  },
  {
    path: "/ios",
    element: <ExternalRedirect url="https://apps.apple.com/fr/app/qalmo/id6502453401" />,
  },
  {
    path: "/support",
    element: <Support />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
